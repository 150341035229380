<template>
  <v-app>
    <router-view />
    <TheSnackbarQueueHandler />
    <TheGlobalProgress />
    <TheConfirmationDialog />
  </v-app>
</template>

<script lang="ts">
import Vue from "vue";
import TheConfirmationDialog from "@/components/TheConfirmationDialog.vue";
import TheSnackbarQueueHandler from "@/components/TheSnackbarQueueHandler.vue";
import TheGlobalProgress from "@/components/TheGlobalProgress.vue";

export default Vue.extend({
  name: "App",
  components: {
    TheGlobalProgress,
    TheConfirmationDialog,
    TheSnackbarQueueHandler,
  },
});
</script>
