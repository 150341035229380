import {
  faArrowRightToBracket,
  faB,
  faBallotCheck,
  faBlockQuote,
  faCamera,
  faCirclePlus,
  faCity,
  faClockRotateLeft,
  faCommentDots,
  faDownload,
  faEnvelopes,
  faEnvelope,
  faEye,
  faFile,
  faFileCircleCheck,
  faFilePen,
  faFileSignature,
  faFloppyDisk,
  faGear,
  faH,
  faHandshake,
  faHouse,
  faListCheck,
  faListTimeline,
  faPaperclip,
  faPaperPlane,
  faRotateRight,
  faScaleBalanced,
  faSquareQuestion,
  faSync,
  faUserPlus,
  faLocationPin,
  faBinarySlash,
  faThumbsUp,
  faThumbsDown,
  faCalendar,
  faTag,
  faCalendarCircleUser,
} from "@fortawesome/pro-solid-svg-icons";

const icons = [
  faCirclePlus,
  faHouse,
  faFile,
  faSync,
  faFileSignature,
  faFloppyDisk,
  faEye,
  faHandshake,
  faClockRotateLeft,
  faBallotCheck,
  faGear,
  faFilePen,
  faArrowRightToBracket,
  faDownload,
  faCity,
  faListTimeline,
  faRotateRight,
  faListCheck,
  faBlockQuote,
  faEnvelopes,
  faEnvelope,
  faCamera,
  faPaperclip,
  faFileCircleCheck,
  faScaleBalanced,
  faCommentDots,
  faPaperPlane,
  faSquareQuestion,
  faUserPlus,
  faLocationPin,
  faBinarySlash,
  faThumbsUp,
  faThumbsDown,
  faTag,
  faCalendarCircleUser,
];

/*project specific icons*/
const identifiers = {
  circlePlus: ["fas", "circle-plus"],
  dashboard: ["fas", "house"],
  document: ["fas", "file"],
  questionnaire: ["fas", "ballot-check"],
  objectionRequest: ["fas", "file-signature"],
  noticeOfObjection: ["fas", "file-pen"],
  partner: ["fas", "handshake"],
  history: ["fas", "clock-rotate-left"],
  reload: ["fas", "sync"],
  save: ["fas", "floppy-disk"],
  view: ["fas", "eye"],
  settings: ["fas", "gear"],
  login: ["fas", "arrow-right-to-bracket"],
  download: ["fas", "download"],
  municipality: ["fas", "city"],
  timeline: ["fas", "list-timeline"],
  rotate: ["fas", "rotate-right"],
  customerTask: ["fas", "list-check"],
  textBlock: ["fas", "block-quote"],
  emailTemplate: ["fas", "envelopes"],
  photo: ["fas", "camera"],
  supplementalDocument: ["fas", "paperclip"],
  validateDocument: ["fas", "file-circle-check"],
  attachment: ["fas", "paperclip"],
  court: ["fas", "scale-balanced"],
  remark: ["fas", "comment-dots"],
  digitalSending: ["fas", "paper-plane"],
  unassociatedDocuments: ["fas", "square-question"],
  addUser: ["fas", "user-plus"],
  mail: ["fas", "envelope"],
  locationPin: ["fas", "location-pin"],
  invalidNumber: ["fas", "binary-slash"],
  approve: ["fas", "thumbs-up"],
  reject: ["fas", "thumbs-down"],
  tag: ["fas", "tag"],
  uploadedAtBy: ["fas", "calendar-circle-user"],
};

export default {
  icons,
  identifiers,
};
