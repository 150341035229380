<template>
  <div class="k-field-wrapper" :class="grid">
    <!-- eslint-disable vue/no-v-html-->
    <div
      v-if="!hideLabel"
      class="font-weight-bold text--primary text-left mb-1 translated-label"
      v-html="translatedLabel"
    />
    <!-- eslint-enable vue/no-v-html-->
    <v-text-field
      :rules="computedRules"
      v-bind="fieldProps"
      background-color="#f8f8f8"
      v-on="$listeners"
    >
      <template v-for="(_, name) in $slots" #[name]>
        <slot :name="name" />
      </template>
    </v-text-field>
  </div>
</template>

<script lang="ts">
import Field from "@/components/crud/fields/FieldMixin.vue";
import Vue from "vue";

export default Vue.extend({
  name: "KTextField",
  mixins: [Field],
});
</script>
