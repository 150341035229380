import RedirectToLoginIfUnauthorisedGuard from "@/router/guards/RedirectToLoginIfUnauthorisedGuard";
import { RouteConfig } from "vue-router";

const authorisedRoutes: RouteConfig = {
  path: "",
  beforeEnter: RedirectToLoginIfUnauthorisedGuard,
  component: () => import("@/templates/Authorised/Authorised.vue"),
  children: [
    {
      path: "/dashboard",
      name: "dashboard",
      component: () => import("@/modules/dashboard/views/Dashboard.vue"),
    },
    {
      path: "document-upload",
      name: "document.upload",
      component: () => import("@/modules/documents/views/BulkUpload.vue"),
    },
    {
      path: "employee",
      name: "employee.index",
      component: () => import("@/modules/employee/views/Table.vue"),
    },
    {
      path: "employee/create",
      name: "employee.create",
      component: () => import("@/modules/employee/views/Form.vue"),
    },
    {
      path: "employee/:id/edit",
      name: "employee.edit",
      component: () => import("@/modules/employee/views/Form.vue"),
    },
    {
      path: "user-role",
      name: "userRole.index",
      component: () => import("@/modules/userRole/views/TableView.vue"),
    },
    {
      path: "user-role/create",
      name: "userRole.create",
      component: () => import("@/modules/userRole/views/FormView.vue"),
    },
    {
      path: "user-role/:id/edit",
      name: "userRole.edit",
      component: () => import("@/modules/userRole/views/FormView.vue"),
    },
    {
      path: "partner",
      name: "partner.index",
      component: () => import("@/modules/partners/views/PartnerTable.vue"),
    },
    {
      path: "partner/create",
      name: "partner.create",
      component: () => import("@/modules/partners/views/PartnerForm.vue"),
    },
    {
      path: "partner/:id/edit",
      name: "partner.edit",
      component: () => import("@/modules/partners/views/PartnerForm.vue"),
    },
    {
      path: "partner/:partnerId/user",
      name: "partner.user.index",
      component: () => import("@/modules/partners/views/UserTable.vue"),
    },
    {
      path: "partner/:partnerId/user/create",
      name: "partner.user.create",
      component: () => import("@/modules/partners/views/UserForm.vue"),
    },
    {
      path: "partner/:partnerId/user/:id/edit",
      name: "partner.user.edit",
      component: () => import("@/modules/partners/views/UserForm.vue"),
    },
    {
      path: "partner-group",
      name: "partnerGroup.index",
      component: () =>
        import("@/modules/partnerGroup/views/PartnerGroupTable.vue"),
    },
    {
      path: "partner-group/create",
      name: "partnerGroup.create",
      component: () =>
        import("@/modules/partnerGroup/views/PartnerGroupForm.vue"),
    },
    {
      path: "partner-group/:id/edit",
      name: "partnerGroup.edit",
      component: () =>
        import("@/modules/partnerGroup/views/PartnerGroupForm.vue"),
    },
    {
      path: "partner-group/:partnerGroupId/user",
      name: "partnerGroup.user.index",
      component: () => import("@/modules/partnerGroup/views/UserTable.vue"),
    },
    {
      path: "partner-group/:partnerGroupId/user/create",
      name: "partnerGroup.user.create",
      component: () => import("@/modules/partnerGroup/views/UserForm.vue"),
    },
    {
      path: "partner-group/:partnerGroupId/user/:id/edit",
      name: "partnerGroup.user.edit",
      component: () => import("@/modules/partnerGroup/views/UserForm.vue"),
    },
    {
      path: "history",
      name: "history.index",
      component: () => import("@/modules/history/views/History.vue"),
    },
    {
      path: "text-block-template",
      name: "textBlockTemplates.index",
      component: () => import("@/modules/textBlockTemplate/views/Table.vue"),
    },
    {
      path: "text-block-template/create",
      name: "textBlockTemplates.create",
      component: () => import("@/modules/textBlockTemplate/views/Form.vue"),
    },
    {
      path: "text-block-template/:id/edit",
      name: "textBlockTemplates.edit",
      component: () => import("@/modules/textBlockTemplate/views/Form.vue"),
    },
    {
      path: "temporary-objection-request",
      name: "temporaryObjectionRequest.index",
      component: () =>
        import(
          "@/modules/temporaryObjectionRequest/views/TemporaryObjectionRequestTable.vue"
        ),
    },
    {
      path: "objection-request",
      name: "objectionRequest.index",
      component: () => import("@/modules/objectionRequest/views/TableView.vue"),
    },
    {
      path: "objection-request/indexed-sales-prices",
      name: "objectionRequest.indexed-sales-prices",
      component: () =>
        import(
          "@/modules/objectionRequestIndexedSalesPrices/views/TableView.vue"
        ),
    },
    {
      path: "objection-request/create",
      name: "objectionRequest.create",
      component: () =>
        import(
          "@/modules/objectionRequest/views/ObjectionRequestCreateForm.vue"
        ),
    },
    {
      path: "objection-request/:objectionRequestId",
      name: "objectionRequest.show",
      component: () =>
        import("@/modules/objectionRequest/views/DossierView.vue"),
    },
    {
      path: "objection-request/:objectionRequestId/documents",
      name: "objectionRequest.documents",
      component: () =>
        import("@/modules/objectionRequest/views/DocumentsView.vue"),
    },
    {
      path: "objection-request/:objectionRequestId/references",
      name: "objectionRequest.references",
      component: () =>
        import("@/modules/objectionRequest/views/ReferencesView.vue"),
    },
    {
      path: "objection-request/:objectionRequestId/questionnaire",
      name: "objectionRequest.questionnaire",
      component: () =>
        import("@/modules/objectionRequest/views/QuestionnaireView.vue"),
    },
    {
      path: "municipality-group",
      name: "municipalityGroup.index",
      component: () =>
        import("@/modules/municipalityGroup/views/MunicipalityGroupTable.vue"),
    },
    {
      path: "municipality-group/email-bounces",
      name: "municipalityGroup.emailBounces",
      component: () =>
        import("@/modules/municipalityGroup/views/EmailBouncesTable.vue"),
    },
    {
      path: "municipality-group/create",
      name: "municipalityGroup.create",
      component: () =>
        import("@/modules/municipalityGroup/views/MunicipalityGroupForm.vue"),
    },
    {
      path: "municipality-group/:id/edit",
      name: "municipalityGroup.edit",
      component: () =>
        import("@/modules/municipalityGroup/views/MunicipalityGroupForm.vue"),
    },
    {
      path: "customer-task",
      name: "customerTask.index",
      component: () =>
        import("@/modules/customerTasks/views/CustomerTaskTable.vue"),
    },
    {
      path: "customer-task/create",
      name: "customerTask.create",
      component: () =>
        import("@/modules/customerTasks/views/CustomerTaskForm.vue"),
    },
    {
      path: "customer-task/:id/edit",
      name: "customerTask.edit",
      component: () =>
        import("@/modules/customerTasks/views/CustomerTaskForm.vue"),
    },
    {
      path: "email-template",
      name: "emailTemplate.index",
      component: () =>
        import("@/modules/emailTemplates/views/EmailTemplateTable.vue"),
    },
    {
      path: "email-template/create",
      name: "emailTemplate.create",
      component: () =>
        import("@/modules/emailTemplates/views/EmailTemplateForm.vue"),
    },
    {
      path: "email-template/:id/edit",
      name: "emailTemplate.edit",
      component: () =>
        import("@/modules/emailTemplates/views/EmailTemplateForm.vue"),
    },
    {
      path: "court",
      name: "court.index",
      component: () => import("@/modules/court/views/CourtTable.vue"),
    },
    {
      path: "court/create",
      name: "court.create",
      component: () => import("@/modules/court/views/CourtForm.vue"),
    },
    {
      path: "court/:id/edit",
      name: "court.edit",
      component: () => import("@/modules/court/views/CourtForm.vue"),
    },
    {
      path: "settings",
      name: "settings.form",
      component: () => import("@/modules/settings/views/SettingsForm.vue"),
    },
    {
      path: "digitally-send-document",
      name: "digitallySendDocument.index",
      component: () =>
        import("@/modules/digitalDocumentBatch/views/DocumentBatchTable.vue"),
    },
    {
      path: "exact-online",
      name: "exactOnline.status",
      component: () => import("@/modules/exact/views/ExactOnlineView.vue"),
    },
    {
      name: "noticeOfObjections.dashboard",
      path: "municipality/:municipalityGroupId/notice-of-objections",
      component: () =>
        import(
          "@/modules/documentGenerator/views/NoticeOfObjectionGeneratableDashboard.vue"
        ),
    },
    {
      name: "invalidAssessmentNumbers",
      path: "invalid-assessment-numbers",
      component: () =>
        import(
          "@/modules/invalidAssessmentNumber/views/InvalidAssessmentNumberTable.vue"
        ),
    },
    {
      path: "*",
      name: "notFound",
      component: () => import("@/modules/notFound/views/NotFound.vue"),
    },
  ],
};

export default authorisedRoutes;
