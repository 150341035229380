import global from "./global.json";
import authorisation from "./authorisation.json";
import actions from "./actions.json";
import openForValidation from "./openForValidation.json";
import crudTable from "./crudTable.json";
import employee from "./employee.json";
import objectionRequest from "./objectionRequest.json";
import partners from "./partners.json";
import textBlockTemplate from "./textBlockTemplate.json";
import reference from "./reference.json";
import validation from "./validation.json";
import documentGenerator from "./documentGenerator.json";
import files from "./files.json";
import municipalityGroup from "./municipalityGroup.json";
import userRole from "./userRole.json";
import customerTask from "./customerTask.json";
import emailTemplate from "./emailTemplate.json";
import settings from "./settings.json";
import supplementalDocument from "./supplementalDocument.json";
import history from "./history.json";
import dashboard from "./dashboard.json";
import exact from "./exact.json";
import court from "./court.json";
import reimbursements from "./reimbursements.json";
import remark from "./remark.json";
import partnerGroup from "./partnerGroup.json";
import digitalDocumentBatch from "./digitalDocumentBatch.json";
import unassociatedDocument from "./unassociatedDocument.json";
import temporaryObjectionRequest from "./temporaryObjectionRequest.json";
import unfinishedTask from "./unfinishedTask.json";
import invalidAssessmentNumber from "./invalidAssessmentNumber.json";
import indexedSalesPrices from "./indexedSalesPrices.json";
import forcedSendingActions from "./forcedSendingActions.json";

export default {
  global,
  authorisation,
  actions,
  openForValidation,
  crudTable,
  employee,
  objectionRequest,
  partners,
  textBlockTemplate,
  reference,
  validation,
  documentGenerator,
  files,
  municipalityGroup,
  userRole,
  customerTask,
  emailTemplate,
  settings,
  supplementalDocument,
  history,
  dashboard,
  exact,
  court,
  reimbursements,
  remark,
  partnerGroup,
  digitalDocumentBatch,
  unassociatedDocument,
  temporaryObjectionRequest,
  unfinishedTask,
  invalidAssessmentNumber,
  indexedSalesPrices,
  forcedSendingActions,
};
