import type {
  DocumentGeneratorRealEstate,
  TextBlock,
} from "@/modules/documentGenerator/api/types";
import { SendingMethod } from "@/application/enum/SendingMethod";

interface State {
  objectionRequestId?: number;
  textBlocks: Partial<TextBlock>[];
  realEstate?: DocumentGeneratorRealEstate;
  suggestedWozValue?: number;
  valuationReport: string | null;
  isFormDirty: boolean;
  sendingMethod?: SendingMethod;
  attachments: {
    documents: number[];
    photos: number[];
  };
  objectionRequestStatus?: {
    name: string;
    id: number;
  };
}

export default {
  namespaced: true,
  state: {
    objectionRequestId: undefined,
    textBlocks: [],
    valuationReport: null,
    suggestedWozValue: undefined,
    objectionRequestStatus: null,
    realEstate: undefined,
    isFormDirty: false,
    sendingMethod: undefined,
    attachments: {
      documents: [],
      photos: [],
    },
  },
  mutations: {
    setTextBlocks(state: State, textBlocks: Partial<TextBlock>[]): void {
      state.textBlocks = textBlocks;
    },
    updateTextBlockText(
      state: State,
      { text, index }: { text: string; index: number }
    ): void {
      state.isFormDirty = true;
      state.textBlocks[index].text = text;
    },
    removeTextBlock(state: State, index: number): void {
      state.isFormDirty = true;
      state.textBlocks.splice(index, 1);
    },
    addTextBlock(state: State, textBlock: Partial<TextBlock>): void {
      state.isFormDirty = true;
      state.textBlocks.push(textBlock);
    },
    setRealEstate(state: State, realEstate: DocumentGeneratorRealEstate): void {
      state.realEstate = realEstate;
    },
    setObjectionRequestStatus(
      state: State,
      status: {
        name: string;
        id: number;
      }
    ): void {
      state.objectionRequestStatus = status;
    },
    setSuggestedWozValue(state: State, suggestedWozValue: number): void {
      state.isFormDirty = true;
      state.suggestedWozValue = suggestedWozValue;
    },
    setValuationReport(state: State, valuationReport: string): void {
      state.valuationReport = valuationReport;
    },
    editTextBlock(
      state: State,
      { index, textBlock }: { index: number; textBlock: TextBlock }
    ): void {
      state.isFormDirty = true;
      state.textBlocks[index] = textBlock;
    },
    togglePhoto(state: State, photoId: number): void {
      state.isFormDirty = true;
      const index = state.attachments.photos.indexOf(photoId);
      if (index > -1) {
        state.attachments.photos.splice(index, 1);
        return;
      }
      state.attachments.photos.push(photoId);
    },
    toggleDocument(state: State, photoId: number): void {
      state.isFormDirty = true;
      const index = state.attachments.documents.indexOf(photoId);
      if (index > -1) {
        state.attachments.documents.splice(index, 1);
        return;
      }
      state.attachments.documents.push(photoId);
    },
    toggleIsHearing(state: State, index: number): void {
      state.isFormDirty = true;
      state.textBlocks[index].isHearing = !state.textBlocks[index].isHearing;
    },
    setObjectionRequestId: (state: State, objectionRequestId: number): void => {
      state.objectionRequestId = objectionRequestId;
    },
    clear(state: State): void {
      state.objectionRequestId = undefined;
      state.textBlocks = [];
      state.valuationReport = null;
      state.suggestedWozValue = undefined;
      state.realEstate = undefined;
      state.isFormDirty = false;
      state.attachments = {
        documents: [],
        photos: [],
      };
    },
    setIsFormDirty(state: State, isFormDirty: boolean): void {
      state.isFormDirty = isFormDirty;
    },
    setSendingMethod(state: State, sendingMethod: SendingMethod): void {
      state.sendingMethod = sendingMethod;
    },
  },
  getters: {
    textBlocks(state: State): Partial<TextBlock>[] {
      return state.textBlocks;
    },
    realEstate(state: State): DocumentGeneratorRealEstate | undefined {
      return state.realEstate;
    },
    objectionRequestStatus(state: State):
      | {
          name: string;
          id: number;
        }
      | undefined {
      return state.objectionRequestStatus;
    },
    objectionRequestId(state: State): number | undefined {
      return state.objectionRequestId;
    },
    documentAttachmentIds(state: State): number[] {
      return state.attachments.documents;
    },
    photoAttachmentIds(state: State): number[] {
      return state.attachments.photos;
    },
    isFormDirty(state: State): boolean {
      return state.isFormDirty;
    },
    valuationReport(state: State): string | null {
      return state.valuationReport;
    },
    sendingMethod(state: State): SendingMethod | undefined {
      return state.sendingMethod;
    },
    suggestedWozValue(state: State): number | undefined {
      return state.suggestedWozValue;
    },
  },
};
